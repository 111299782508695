import {SessionQuery} from '@/core/session/state/session.query';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ID, setLoading} from '@datorama/akita';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ApiResponse} from '../../types/api/api-response';
import {handleError} from '../../utils';
import {getHttpParams} from '../../utils/functions/http-params';
import {MicrosoftGraphUserFilterPresets} from './enums/microsoft-graph-user-filter-presets.enum';
import {MicrosoftGraphUser} from './microsoft-graph-user.model';
import {MicrosoftGraphUsersStore} from './microsoft-graph-users.store';

@Injectable({providedIn: 'root'})
export class MicrosoftGraphUsersService {
  static readonly microsoftGraphUserAttributes = 'id,display_name,given_name,mail,surname,job_title,user_principal_name';

  constructor(
    private microsoftGraphUsersStore: MicrosoftGraphUsersStore,
    private http: HttpClient,
    private sessionQuery: SessionQuery,
    private snackBar: MatSnackBar
  ) {
  }

  getOnlyWithMailAccounts() {
    return this.get(MicrosoftGraphUserFilterPresets.OnlyWithMail);
  }

  getOnlyActiveAccounts() {
    return this.get(MicrosoftGraphUserFilterPresets.OnlyActiveAccounts);
  }

  getWithoutGuestAccounts() {
    return this.get(MicrosoftGraphUserFilterPresets.WithoutGuestAccounts);
  }

  get(filterPreset: string = null) {
    let params = getHttpParams({
      tenant_id: this.sessionQuery.tenantId?.toString(),
      attributes: MicrosoftGraphUsersService.microsoftGraphUserAttributes,
    });

    if (filterPreset) {
      params = params.set('filterPreset', filterPreset);
    }

    const options = {params};

    return this.http
      .get<ApiResponse<MicrosoftGraphUser[]>>(environment.api.baseUrl + 'microsoft-graph/users', options)
      .pipe(
        setLoading(this.microsoftGraphUsersStore),
        catchError((error: HttpErrorResponse) => handleError(error, this.snackBar, this.microsoftGraphUsersStore)),
        tap((response) => {
          this.microsoftGraphUsersStore.set(response.data);
        }),
      );
  }

  add(microsoftGraphUser: MicrosoftGraphUser) {
    this.microsoftGraphUsersStore.add(microsoftGraphUser);
  }

  update(id, microsoftGraphUser: Partial<MicrosoftGraphUser>) {
    this.microsoftGraphUsersStore.update(id, microsoftGraphUser);
  }

  remove(id: ID) {
    this.microsoftGraphUsersStore.remove(id);
  }
}
